@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

.faIcon {
  -webkit-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.faIcon:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#newsEntry a {
    font-weight:bold;
    background-image: -webkit-linear-gradient(left,#e42b0e,#981558);
    background-image: linear-gradient(to right,#e42b0e,#981558);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
