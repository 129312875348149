@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

.faIcon {
  transition: all 100ms ease-in;
}

.faIcon:hover {
  filter: brightness(80%);
  transform: scale(1.1);
}
